export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "contents": {
          "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
          "swissMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss made"])}
        }
      },
      "fr": {
        "contents": {
          "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
          "swissMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabriqué en Suisse"])}
        }
      },
      "de": {
        "contents": {
          "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
          "swissMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss made"])}
        }
      },
      "it": {
        "contents": {
          "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattaci"])},
          "swissMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss made"])}
        }
      }
    }
  })
}
