<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    fill="none"
    viewBox="0 0 877 225"
  >
    <path
      d="M834.324 159.7c-5.941 0-10.479-1.661-13.614-4.983-3.087-3.368-4.631-8.468-4.631-15.298V107.98h-1.965c-1.31 0-2.269-.491-2.877-1.473-.562-.983-.842-2.574-.842-4.772v-2.106c0-2.199.28-3.789.842-4.772.608-.983 1.567-1.474 2.877-1.474h1.965l1.614-11.86c.374-2.62 2.62-3.93 6.737-3.93h3.929c2.152 0 3.72.328 4.702.983 1.029.655 1.544 1.637 1.544 2.947v11.86h6.105c1.31 0 2.246.491 2.807 1.474.609.983.913 2.573.913 4.772v2.106c0 2.198-.304 3.789-.913 4.772-.561.982-1.497 1.473-2.807 1.473h-6.105v31.649c0 1.918.328 3.299.983 4.141.701.842 1.847 1.263 3.438 1.263.468 0 1.1-.07 1.895-.211.795-.187 1.286-.28 1.474-.28.748 0 1.403.631 1.964 1.894.609 1.217 1.076 2.573 1.404 4.071.374 1.497.561 2.502.561 3.017 0 4.117-4 6.176-12 6.176z"
      fill="url(#A)"
    />
    <path
      d="M776.921 159.7c-10.948 0-19.111-2.924-24.491-8.772-5.381-5.895-8.071-14.059-8.071-24.492 0-11.742 2.69-20.35 8.071-25.824s12.795-8.211 22.245-8.211c6.269 0 11.392 1.427 15.369 4.281 4.023 2.854 6.947 6.69 8.772 11.509 1.871 4.819 2.807 10.269 2.807 16.351 0 .701-.422 1.637-1.264 2.807-.795 1.169-1.707 2.222-2.736 3.158s-1.825 1.403-2.386 1.403h-31.79c.421 4.772 1.825 8.164 4.211 10.176 2.432 1.964 5.801 2.947 10.105 2.947 2.339 0 4.444-.257 6.316-.772 1.918-.561 3.953-1.287 6.105-2.175 1.31-.609 2.199-.913 2.667-.913.842 0 1.754.608 2.736 1.825 1.03 1.169 1.895 2.526 2.597 4.07.748 1.544 1.123 2.737 1.123 3.579 0 1.918-1.31 3.556-3.93 4.912-2.573 1.357-5.638 2.386-9.193 3.088-3.509.702-6.597 1.053-9.263 1.053zm6.737-40.071c0-3.836-.749-6.9-2.246-9.193-1.497-2.339-3.743-3.508-6.737-3.508-6.175 0-9.918 4.234-11.228 12.701h20.211z"
      fill="url(#B)"
    />
    <path
      d="M689.903 158.857c-5.006 0-7.508-1.31-7.508-3.93V68.612c0-1.357.608-2.363 1.824-3.018 1.216-.702 3.111-1.053 5.684-1.053h3.439c5.006 0 7.509 1.357 7.509 4.07v49.333l.421.14 15.158-22.526c.655-.982 1.567-1.614 2.736-1.895 1.17-.328 2.784-.491 4.843-.491h4.631c4.679 0 7.018.632 7.018 1.895 0 .655-.398 1.45-1.193 2.386l-17.263 24.772 20.28 32.14c.702 1.17 1.053 2.035 1.053 2.597 0 1.263-2.386 1.894-7.158 1.894h-5.193c-2.012 0-3.649-.163-4.912-.491s-2.129-.959-2.597-1.895l-17.403-28.701-.421.21v26.947c0 2.62-2.503 3.93-7.509 3.93h-3.439z"
      fill="url(#C)"
    />
    <path
      d="M643.729 158.858c-4.96 0-7.439-1.31-7.439-3.93v-39.579c0-5.989-.398-10.971-1.193-14.948-.234-1.263-.351-2.152-.351-2.666 0-.936.866-1.684 2.597-2.246 1.777-.608 3.766-1.053 5.964-1.333 2.246-.281 4.047-.421 5.404-.421 1.45 0 2.456.538 3.018 1.614.608 1.076 1.146 2.643 1.614 4.701l.421 1.825c1.59-2.9 3.415-5.193 5.473-6.877 2.106-1.731 4.796-2.597 8.07-2.597 2.714 0 4.585.702 5.615 2.105 1.029 1.357 1.543 2.901 1.543 4.632 0 .936-.163 2.269-.491 4-.327 1.684-.795 3.181-1.403 4.491s-1.287 1.965-2.035 1.965c-.515 0-1.287-.14-2.316-.421-1.404-.374-2.597-.561-3.579-.561-2.293 0-4.304.655-6.035 1.965-1.684 1.263-2.971 2.69-3.86 4.281v40.07c0 2.62-2.526 3.93-7.579 3.93h-3.438z"
      fill="url(#D)"
    />
    <path
      d="M584.15 159.7c-6.363 0-11.041-1.661-14.036-4.983-2.947-3.368-4.421-7.813-4.421-13.333 0-4.164.866-7.907 2.597-11.228 1.731-3.369 5.24-6.199 10.526-8.491s12.842-3.439 22.667-3.439v-4.14c0-2.433-.772-4.234-2.316-5.404s-4-1.754-7.368-1.754c-2.807 0-5.217.304-7.228.912-2.012.561-4.188 1.333-6.527 2.316-1.216.561-1.965.842-2.245.842-.842 0-1.778-.632-2.807-1.895-.983-1.263-1.848-2.713-2.597-4.351s-1.123-2.877-1.123-3.719c0-1.45 1.03-2.831 3.088-4.14 2.105-1.357 4.982-2.433 8.632-3.228 3.649-.842 7.696-1.263 12.14-1.263 9.357 0 16.164 1.871 20.421 5.614 4.257 3.696 6.386 8.772 6.386 15.228v23.369c0 3.883.094 6.9.281 9.053a95.5 95.5 0 0 0 .912 6.385c.234 1.264.351 2.153.351 2.667 0 .936-.889 1.708-2.667 2.316-1.731.608-3.719 1.053-5.965 1.333s-4.047.421-5.403.421c-1.451 0-2.503-.608-3.158-1.824-.608-1.263-1.1-2.971-1.474-5.123l-.14-.842a5.49 5.49 0 0 0-.211-.842c-.468.889-1.473 2.082-3.017 3.579-1.497 1.497-3.556 2.877-6.176 4.14-2.62 1.216-5.661 1.825-9.122 1.825zm6.105-14.667c2.339 0 4.538-.725 6.596-2.175 2.059-1.498 3.603-3.392 4.632-5.685v-6.877c-11.649 0-17.474 3.064-17.474 9.193 0 1.638.538 2.971 1.614 4 1.123 1.029 2.667 1.544 4.632 1.544z"
      fill="url(#E)"
    />
    <path
      d="M533.974 92.401c6.223 0 10.807 1.637 13.755 4.912 2.994 3.275 4.491 8.351 4.491 15.228v42.386c0 2.62-2.526 3.93-7.579 3.93h-3.439c-4.959 0-7.438-1.31-7.438-3.93v-39.579c0-2.527-.585-4.445-1.755-5.755-1.122-1.31-3.017-1.965-5.684-1.965-2.339 0-4.327.726-5.965 2.176-1.59 1.403-2.877 3.041-3.859 4.912v40.211c0 2.62-2.503 3.93-7.509 3.93h-3.509c-4.959 0-7.438-1.31-7.438-3.93v-39.579c0-2.527-.585-4.445-1.755-5.755-1.123-1.31-2.994-1.965-5.614-1.965-2.245 0-4.234.749-5.965 2.246-1.684 1.497-2.971 3.158-3.859 4.983v40.07c0 2.62-2.527 3.93-7.579 3.93h-3.439c-4.959 0-7.439-1.31-7.439-3.93v-39.579c0-5.989-.397-10.971-1.193-14.948-.233-1.263-.35-2.152-.35-2.666 0-.936.865-1.684 2.596-2.246 1.778-.608 3.766-1.053 5.965-1.333 2.246-.281 4.047-.421 5.403-.421 1.451 0 2.457.538 3.018 1.614.608 1.076 1.146 2.643 1.614 4.701l.421 1.825c1.965-2.667 4.585-4.912 7.86-6.737s6.807-2.737 10.596-2.737c4.304 0 7.79.772 10.456 2.316 2.714 1.544 4.702 3.93 5.965 7.158 2.106-2.667 4.866-4.912 8.281-6.737s7.064-2.737 10.947-2.737z"
      fill="url(#F)"
    />
    <path
      d="M419.167 159.7c-9.871 0-17.403-2.901-22.596-8.702-5.146-5.801-7.719-14.152-7.719-25.053 0-10.853 2.573-19.157 7.719-24.912 5.193-5.754 12.725-8.631 22.596-8.631s17.357 2.877 22.456 8.631c5.147 5.708 7.72 14.012 7.72 24.912s-2.573 19.252-7.72 25.053c-5.099 5.801-12.584 8.702-22.456 8.702zm0-14.667c3.93 0 6.807-1.59 8.632-4.772 1.871-3.181 2.807-7.953 2.807-14.316 0-6.315-.936-11.064-2.807-14.245-1.825-3.181-4.702-4.772-8.632-4.772-7.766 0-11.649 6.339-11.649 19.017 0 6.316.936 11.088 2.807 14.316 1.918 3.182 4.866 4.772 8.842 4.772z"
      fill="url(#G)"
    />
    <path
      d="M368.641 159.7c-5.942 0-10.48-1.661-13.614-4.983-3.088-3.368-4.632-8.468-4.632-15.298V107.98h-1.965c-1.31 0-2.269-.491-2.877-1.473-.561-.983-.842-2.573-.842-4.772V99.63c0-2.199.281-3.789.842-4.772.608-.982 1.567-1.474 2.877-1.474h1.965l1.614-11.86c.374-2.62 2.62-3.93 6.737-3.93h3.93c2.152 0 3.719.328 4.702.982 1.029.655 1.543 1.638 1.543 2.947v11.86h6.106c1.31 0 2.245.491 2.807 1.474.608.983.912 2.573.912 4.772v2.105c0 2.199-.304 3.789-.912 4.772-.562.982-1.497 1.473-2.807 1.473h-6.106v31.65c0 1.918.328 3.298.983 4.14.702.842 1.848 1.263 3.439 1.263.467 0 1.099-.07 1.894-.21.796-.188 1.287-.281 1.474-.281.748 0 1.403.631 1.965 1.895.608 1.216 1.076 2.573 1.403 4.07.375 1.497.562 2.503.562 3.017 0 4.117-4 6.176-12 6.176z"
      fill="url(#H)"
    />
    <path
      d="M297.062 159.7c-6.269 0-10.901-1.638-13.895-4.913-2.994-3.321-4.491-8.444-4.491-15.368V97.103c0-1.31.608-2.292 1.824-2.947s3.111-.983 5.685-.983h3.438c2.573 0 4.468.328 5.684.983s1.825 1.637 1.825 2.947v39.509c0 2.573.585 4.515 1.754 5.824s3.088 1.965 5.755 1.965c2.292 0 4.28-.725 5.965-2.175 1.731-1.497 3.064-3.181 4-5.053v-40.07c0-1.31.608-2.292 1.824-2.947s3.111-.983 5.684-.983h3.439c2.573 0 4.468.328 5.684.983s1.825 1.637 1.825 2.947v39.509c0 3.93.093 7.017.28 9.263.234 2.199.515 4.257.843 6.176.233 1.263.35 2.152.35 2.666 0 .936-.865 1.708-2.596 2.316s-3.719 1.053-5.965 1.333c-2.199.281-3.977.421-5.333.421-1.497 0-2.573-.584-3.228-1.754-.609-1.216-1.1-2.924-1.474-5.123l-.351-1.754c-1.965 2.666-4.608 4.935-7.93 6.807-3.275 1.824-6.807 2.737-10.596 2.737z"
      fill="url(#I)"
    />
    <path
      d="M202.466 158.857c-5.1 0-7.65-.912-7.65-2.736 0-.515.164-1.264.492-2.246l27.508-80.21c.655-1.778 3.018-2.667 7.088-2.667h6.456c4.07 0 6.433.889 7.088 2.667l27.509 80.211c.234.936.351 1.684.351 2.246 0 .935-.515 1.637-1.544 2.105-.983.421-2.55.631-4.702.631h-5.474c-1.965 0-3.555-.187-4.772-.561-1.169-.421-1.894-1.099-2.175-2.035l-5.053-15.228h-29.403l-5.053 15.228c-.514 1.731-2.433 2.596-5.754 2.596h-4.912zm39.789-34.315l-7.088-22.176c-.421-1.123-.795-2.409-1.123-3.859l-.771-4.14h-.492l-.28 1.544c-.468 2.76-1.006 4.912-1.614 6.456l-7.369 22.176h18.737z"
      fill="url(#J)"
    />
    <path
      fill-rule="evenodd"
      d="M145.51 141.26c-2.402 4.134-7.066 6.867-12.365 6.867h-52.64l-7.772 13.333h84.789v13.334H72.733c-10.74 0-17.523-10.867-12.365-19.8l9.539-16.267-25.437-50.6H30.338V74.794h23.105l6.642 13.333h104.573c5.37 0 8.762 5.467 6.147 9.867L145.51 141.26zm7.137-39.8H66.798l16.746 33.334h49.602l19.501-33.334zm-79.92 80c-7.772 0-14.061 6-14.061 13.334s6.288 13.333 14.061 13.333 14.132-6 14.132-13.333S80.5 181.46 72.727 181.46zm56.603 13.334c0-7.334 6.288-13.334 14.061-13.334s14.131 6 14.131 13.334-6.359 13.333-14.131 13.333-14.061-6-14.061-13.333z"
      fill="url(#K)"
    />
    <path
      d="M71.809 79.985c-.639-1.724 0-15.197 0-15.197s0-13.13.312-15.059 3.53-5.344 4.359-6.4c1-1.274 2.595-4.919 4.736-10.826 3.648-10.062 3.578-14.432 11.566-16.388 0 0 0 0 38.714 0 6.242 1.362 7.641 6.012 9.274 10.405 4.925 13.245 5.622 14.865 7.429 17.278 4.226 5.644 3.922 4.152 4.269 20.935 0 0 .904 13.678.31 14.974s-3.253 3.034-5.973 3.034c-2.462 0-3.185-.192-4.27-1.132-1.213-1.052-1.318-1.422-1.472-5.204l-.166-4.072-1.463.268c-10.426 1.911-44.205 1.905-54.64-.009l-1.513-.278v3.36c0 3.588-.755 5.75-2.301 6.584-.493.266-2.182.483-3.755.483-2.573 0-4.778-1.031-5.417-2.755zm17.293-20.43c3.056-3.081 1.507-8.342-2.656-9.023-5.189-.849-8.052 5.954-3.924 9.324 1.562 1.276 5.18 1.11 6.58-.301zm52.084.598c1.17-.538 2.604-2.939 2.604-4.361 0-2.652-2.629-5.427-5.143-5.427-3.41 0-6.125 3.661-5.081 6.851.994 3.035 4.481 4.379 7.62 2.937zm-17.33-3.527c.572-.522.851-1.435.851-2.789 0-3.375-.358-3.473-12.692-3.473h-10.623c-2.285.035-2.184 1.732-2.285 3.294-.094 1.456.817 2.87 1.583 3.385.281.19 5.418.348 11.413.352 9.497.006 11.011-.093 11.753-.769zM86.663 40.465s42.415-1.334 51.076.02c1.408.22 2.609.355 2.668.301.129-.119-2.021-6.486-4.052-12-2.732-7.415-1.447-7.042-24.238-7.042-20.504 0-20.853.043-22.465 2.795-.983 1.678-5.928 15.792-5.636 16.087.115.116 1.306.044 2.647-.161z"
      fill="url(#L)"
    />
    <defs>
      <linearGradient
        id="A"
        x1="828.359"
        y1="77.594"
        x2="828.359"
        y2="159.7"
        xlink:href="#M"
      >
        <stop stop-color="#ff900d" />
        <stop offset="0" stop-color="#ffae4f" />
        <stop offset=".147" stop-color="#fd9c2a" />
        <stop offset=".48" stop-color="#ff900d" />
      </linearGradient>
      <linearGradient
        id="B"
        x1="772.991"
        y1="92.401"
        x2="772.991"
        y2="159.7"
        xlink:href="#M"
      >
        <stop stop-color="#ff900d" />
        <stop offset="0" stop-color="#ffae4f" />
        <stop offset=".147" stop-color="#fd9c2a" />
        <stop offset=".48" stop-color="#ff900d" />
      </linearGradient>
      <linearGradient
        id="C"
        x1="710.465"
        y1="64.542"
        x2="710.465"
        y2="158.857"
        xlink:href="#M"
      >
        <stop stop-color="#ff900d" />
        <stop offset="0" stop-color="#ffae4f" />
        <stop offset=".147" stop-color="#fd9c2a" />
        <stop offset=".48" stop-color="#ff900d" />
      </linearGradient>
      <linearGradient
        id="D"
        x1="654.606"
        y1="92.401"
        x2="654.606"
        y2="158.858"
        xlink:href="#M"
      >
        <stop stop-color="#ff900d" />
        <stop offset="0" stop-color="#ffae4f" />
        <stop offset=".147" stop-color="#fd9c2a" />
        <stop offset=".48" stop-color="#ff900d" />
      </linearGradient>
      <linearGradient
        id="E"
        x1="593.588"
        y1="92.401"
        x2="593.588"
        y2="159.7"
        xlink:href="#M"
      >
        <stop stop-color="#ff900d" />
        <stop offset="0" stop-color="#ffae4f" />
        <stop offset=".147" stop-color="#fd9c2a" />
        <stop offset=".48" stop-color="#ff900d" />
      </linearGradient>
      <linearGradient
        id="F"
        x1="506.536"
        y1="92.401"
        x2="506.536"
        y2="158.858"
        xlink:href="#M"
      >
        <stop stop-color="#ff900d" />
        <stop offset="0" stop-color="#ffae4f" />
        <stop offset=".147" stop-color="#fd9c2a" />
        <stop offset=".48" stop-color="#ff900d" />
      </linearGradient>
      <linearGradient
        id="G"
        x1="419.097"
        y1="68.965"
        x2="419.097"
        y2="159.7"
        xlink:href="#M"
      >
        <stop stop-color="#bbb" />
        <stop offset=".314" stop-color="#646464" />
        <stop offset=".871" stop-color="#292929" />
        <stop offset="1" stop-color="#1e1e1e" />
      </linearGradient>
      <linearGradient
        id="H"
        x1="362.676"
        y1="49.001"
        x2="362.676"
        y2="159.7"
        xlink:href="#M"
      >
        <stop stop-color="#bbb" />
        <stop offset=".314" stop-color="#646464" />
        <stop offset=".871" stop-color="#292929" />
        <stop offset="1" stop-color="#1e1e1e" />
      </linearGradient>
      <linearGradient
        id="I"
        x1="306.606"
        y1="70.005"
        x2="306.606"
        y2="159.7"
        xlink:href="#M"
      >
        <stop stop-color="#bbb" />
        <stop offset=".314" stop-color="#646464" />
        <stop offset=".871" stop-color="#292929" />
        <stop offset="1" stop-color="#1e1e1e" />
      </linearGradient>
      <linearGradient
        id="J"
        x1="233.062"
        y1="40.401"
        x2="233.062"
        y2="158.857"
        xlink:href="#M"
      >
        <stop stop-color="#bbb" />
        <stop offset=".314" stop-color="#646464" />
        <stop offset=".871" stop-color="#292929" />
        <stop offset="1" stop-color="#1e1e1e" />
      </linearGradient>
      <linearGradient
        id="K"
        x1="101.022"
        y1="74.794"
        x2="101.022"
        y2="208.127"
        xlink:href="#M"
      >
        <stop stop-color="#ff900d" />
        <stop offset="0" stop-color="#ffae4f" />
        <stop offset=".147" stop-color="#fd9c2a" />
        <stop offset=".48" stop-color="#ff900d" />
      </linearGradient>
      <linearGradient
        id="L"
        x1="112.253"
        y1="-7.089"
        x2="112.253"
        y2="82.74"
        xlink:href="#M"
      >
        <stop stop-color="#bbb" />
        <stop offset=".314" stop-color="#646464" />
        <stop offset=".871" stop-color="#292929" />
        <stop offset="1" stop-color="#1e1e1e" />
      </linearGradient>
      <linearGradient id="M" gradientUnits="userSpaceOnUse" />
    </defs>
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "classic-logo",
  props: {
    width: {
      type: String,
      default: "877",
    },

    height: {
      type: String,
      default: "225",
    },
  },
});
</script>
