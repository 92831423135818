<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    fill="none"
    viewBox="0 0 26 25"
  >
    <path fill="#da291c" d="M.035.419h25.479v25H.035z" />
    <path
      d="M15.275 4.919h-5v5.5h-5.5v5h5.5v5.5h5v-5.5h5.5v-5h-5.5v-5.5z"
      fill="#fff"
    />
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "swiss-flag",
  props: {
    width: {
      type: String,
      default: "26",
    },

    height: {
      type: String,
      default: "26",
    },
  },
});
</script>
