<template>
  <div>
    <!-- Main layout structure using Quasar components -->
    <q-layout view="hHh lpr fff">
      <q-header bordered class="bg-white">
        <q-toolbar class="relative">
          <div class="full-width flex q-pr-xl q-pr-md-none justify-md-center">
            <classic-logo
              class="q-ml-md q-mr-lg q-mr-md-md q-my-sm"
              width="300"
              height="80"
            />
          </div>
          <!-- v-if="!$route.params.locale" -->
          <div class="absolute-right q-pr-sm flex">
            <locale-select class="self-center" />
          </div>
        </q-toolbar>
      </q-header>
      <q-footer class="bg-white text-dark q-px-lg q-py-md">
        <div class="row">
          <div
            class="col-12 col-sm-6"
            :class="{ 'text-center': $q.screen.lt.sm }"
          >
            <p class="q-mb-none q-mt-xs-auto">
              {{ t("contents.contactUs") }}
              <br />
              <a href="mailto:juicywebsarl@gmail.com" class="text-primary">
                juicywebsarl@gmail.com
              </a>
            </p>
          </div>
          <div
            class="col-12 col-sm-6 flex justify-center justify-sm-end q-mt-md"
          >
            <swiss-flag />
            <span class="q-ml-sm">
              {{ t("contents.swissMade") }}
            </span>
          </div>
        </div>
      </q-footer>
      <!-- Main content area -->
      <q-page-container>
        <router-view></router-view>
      </q-page-container>
    </q-layout>
  </div>
</template>
<i18n>
  {
    "en-US": {
      "contents": {
          "contactUs": "Contact us",
          "swissMade": "Swiss made",
      },
    },
    "fr": {
      "contents": {
        "contactUs": "Contactez-nous",
        "swissMade": "Fabriqué en Suisse",
      },
    },
    "de": {
      "contents": {
        "contactUs": "Kontaktieren Sie uns",
        "swissMade": "Swiss made",
      },
    },
    "it": {
      "contents": {
        "contactUs": "Contattaci",
        "swissMade": "Swiss made",
      },
    },
  }
</i18n>
<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import ClassicLogo from "components/svg/ClassicLogo.vue";
import SwissFlag from "components/svg/SwissFlag.vue";
import LocaleSelect from "components/LocaleSelect.vue";

export default defineComponent({
  name: "simplified-layout",
  components: {
    ClassicLogo,
    SwissFlag,
    LocaleSelect,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
</script>
