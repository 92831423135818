<template>
  <!-- v-if="!$route.params.locale" -->
  <q-select
    v-model="locale"
    :options="localesOptions"
    dense
    borderless
    emit-value
    map-options
    options-dense
    class="custom-select"
    display-value-html
  >
    <template v-slot:selected-item="scope">
      <q-icon :name="scope.opt.icon" class="q-mr-sm" size="xs" />
      {{ scope.opt.label }}
    </template>

    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section avatar>
          <q-icon :name="scope.opt.icon" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ scope.opt.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import { defineComponent, watch, useSSRContext } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";
import { getRouteNameWithLocale } from "src/utils/routesUtils";
const localesOptions = [
  { value: "de", label: "DE", icon: "svguse:flags.svg#flag-de" },
  { value: "en-US", label: "EN", icon: "svguse:flags.svg#flag-gb" },
  { value: "fr", label: "FR", icon: "svguse:flags.svg#flag-fr" },
  { value: "it", label: "IT", icon: "svguse:flags.svg#flag-it" },
];

export default defineComponent({
  name: "locale-select",

  setup() {
    const $q = useQuasar();
    const router = useRouter();
    const route = useRoute();
    const ssrContext = process.env.SERVER ? useSSRContext() : null;

    const { locale, t } = useI18n();

    watch(locale, async (val) => {
      try {
        // if (process.env.CLIENT)
        if (val === "en-US") {
          $q.cookies.remove("client_locale", {
            path: "/",
          });
        } else {
          $q.cookies.set("client_locale", val, {
            expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
            path: "/",
          });
        }
      } catch (e) {
        // data wasn't successfully saved due to
        // a Web Cookies API error
      }

      // dynamic import, so loading on demand only
      import(
        /* webpackInclude: /(de|en-US|fr|it)\.js$/ */
        "quasar/lang/" + val
      ).then((lang) => {
        if (process.env.SERVER) $q.lang.set(lang.default, ssrContext);
        if (process.env.CLIENT) $q.lang.set(lang.default);
      });

      router.push({
        name: getRouteNameWithLocale(route.name, val),
      });
    });

    return {
      t,
      locale,
      localesOptions,
    };
  },
});
</script>

<style>
.custom-select {
  min-width: 50px;
}
</style>
